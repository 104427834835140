<template>
  <div class="inner-section">
    <card>
      <template v-slot:searchHeaderTitle>
        <h4 class="card-title">
          {{ $t('elearning_tpm.course_module') }}
          <slot v-if="$route.query.training_title_id">
            {{ $t('globalTrans.update') }}
          </slot>
          <slot v-else>
            {{ $t('globalTrans.entry') }}
          </slot>
        </h4>
      </template>
      <template v-slot:searchHeaderAction>
        <router-link to="course-module" :class="'btn-add'"><i class="ri-arrow-left-line"></i> {{ $t('elearning_tpm.course_module') }} {{ $t('globalTrans.list') }}</router-link>
      </template>
      <template v-slot:searchBody>
      <b-overlay :show="loading">
        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
          <b-form class="price-entry" id="form" @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" >
            <b-overlay :show="loading">
              <b-row>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Organization"  vid="org_id" rules="required|min_value:1">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="org_id"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('globalTrans.organization')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="formData.org_id"
                        :options="orgList"
                        id="org_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Training Type"  vid="training_type_id" rules="required|min_value:1">
                    <b-form-group
                        class="row"
                        label-cols-sm="4"
                        label-for="training_type_id"
                        slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.training_type')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="formData.training_type_id"
                        :options="trainingTypeList"
                        id="training_type_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                        <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col lg="6" sm="6">
                  <ValidationProvider name="Training Title" vid="training_title_id" rules="required|min_value:1">
                    <b-form-group
                      class="row"
                      label-cols-sm="4"
                      label-for="training_title_id"
                      slot-scope="{ valid, errors }"
                    >
                      <template v-slot:label>
                        {{$t('elearning_config.training_title')}} <span class="text-danger">*</span>
                      </template>
                      <b-form-select
                        plain
                        v-model="formData.training_title_id"
                        :options="trainingTitleList"
                        id="training_title_id"
                        :state="errors[0] ? false : (valid ? true : null)"
                        >
                        <template v-slot:first>
                            <b-form-select-option :value="0">{{$t('globalTrans.select')}}</b-form-select-option>
                        </template>
                        </b-form-select>
                      <div class="invalid-feedback">
                        {{ errors[0] }}
                      </div>
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
              </b-row>
            </b-overlay>
            <body-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">{{ $t('elearning_tpm.course_module') }}</h4>
              </template>
              <template v-slot:body>
                <b-table-simple class="mb-0" hover small caption-top responsive bordered>
                  <b-tr style="background: #f9f9f9">
                    <b-th style="width: 15%;">{{ $t('elearning_tpm.course_module_en') }}</b-th>
                    <b-th style="width: 15%;">{{ $t('elearning_tpm.course_module_bn') }}</b-th>
                    <b-th style="width: 25%;">{{ $t('elearning_tpm.course_module_details_en') }}</b-th>
                    <b-th style="width: 25%;">{{ $t('elearning_tpm.course_module_details_bn') }}</b-th>
                    <b-th style="width: 15%;">{{ $t('globalTrans.attachment') }}</b-th>
                    <b-th style="width: 5%;">{{ $t('globalTrans.action') }}</b-th>
                  </b-tr>
                  <b-tr v-for="(course_module, index) in formData.course_modules" :key="index">
                    <b-td>
                      <keep-alive>
                        <ValidationProvider :vid="'course_module' + index" rules="required" name="Lesson Module (En)">
                          <div slot-scope="{ valid, errors }">
                            <b-form-input
                              rows="2"
                              v-model="course_module.course_module"
                              :name="'course_modules['+index+'][course_module]'"
                              :id="'course_modules['+index+'][course_module]'"
                              :placeholder="$t('elearning_tpm.course_module_en')"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback" role="alert">
                              {{ errors[0] }}
                            </div>
                            <small v-if="attachments_errors && attachments_errors['course_modules.' + index + '.course_module']" class="text-danger">
                              {{ attachments_errors['course_modules.'+index+'.course_module'] }}
                            </small>
                          </div>
                        </ValidationProvider>
                      </keep-alive>
                    </b-td>
                    <b-td>
                      <keep-alive>
                        <ValidationProvider :vid="'course_module_bn' + index" rules="required"  name="Lesson Module (Bn)">
                          <div slot-scope="{ valid, errors }">
                            <b-form-input
                              rows="2"
                              v-model="course_module.course_module_bn"
                              :name="'course_modules['+index+'][course_module_bn]'"
                              :id="'course_modules['+index+'][course_module_bn]'"
                              :placeholder="$t('elearning_tpm.course_module_bn')"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-input>
                            <div class="error invalid-feedback" role="alert">
                              {{ errors[0] }}
                            </div>
                            <small v-if="attachments_errors && attachments_errors['course_modules.' + index + '.course_module_bn']" class="text-danger">
                              {{ attachments_errors['course_modules.'+index+'.course_module_bn'] }}
                            </small>
                          </div>
                        </ValidationProvider>
                      </keep-alive>
                    </b-td>
                    <b-td>
                      <keep-alive>
                        <ValidationProvider :vid="'course_module_details' + index"  name="Lesson Module Details (En)">
                          <div slot-scope="{ valid, errors }">
                            <b-form-textarea
                              rows="2"
                              v-model="course_module.course_module_details"
                              :name="'course_modules['+index+'][course_module_details]'"
                              :id="'course_modules['+index+'][course_module_details]'"
                              :placeholder="$t('elearning_tpm.course_module_details')"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-textarea>
                            <div class="error invalid-feedback" role="alert">
                              {{ errors[0] }}
                            </div>
                            <small v-if="attachments_errors && attachments_errors['course_modules.' + index + '.course_module_details']" class="text-danger">
                              {{ attachments_errors['course_modules.'+index+'.course_module_details'] }}
                            </small>
                          </div>
                        </ValidationProvider>
                      </keep-alive>
                    </b-td>
                    <b-td>
                      <keep-alive>
                        <ValidationProvider :vid="'course_module_details_bn' + index" name="Lesson Module Details (Bn)">
                          <div slot-scope="{ valid, errors }">
                            <b-form-textarea
                              rows="2"
                              v-model="course_module.course_module_details_bn"
                              :name="'course_modules['+index+'][course_module_details_bn]'"
                              :id="'course_modules['+index+'][course_module_details_bn]'"
                              :placeholder="$t('elearning_tpm.course_module_details_bn')"
                              :state="errors[0] ? false : (valid ? true : null)"
                            ></b-form-textarea>
                            <div class="error invalid-feedback" role="alert">
                              {{ errors[0] }}
                            </div>
                            <small v-if="attachments_errors && attachments_errors['course_modules.' + index + '.name']" class="text-danger">
                              {{ attachments_errors['course_modules.'+index+'.name'] }}
                            </small>
                          </div>
                        </ValidationProvider>
                      </keep-alive>
                    </b-td>
                    <b-td>
                      <input type="hidden" :name="'course_modules['+index+'][id]'" v-model="course_module.id"/>
                      <input type="hidden" :name="'course_modules['+index+'][course_id]'" v-model="course_module.course_id"/>
                      <input type="hidden" :name="'course_modules['+index+'][pre_attachment]'" v-model="course_module.attachment"/>
                      <b-form-file
                        :name="'course_modules['+index+'][attachment]'"
                        :data-id="course_module.id"
                        class="mt-4"
                      ></b-form-file>
                      <small v-if="attachments_errors && attachments_errors['course_modules.' + index + '.attachment']" class="text-danger">
                        {{ attachments_errors['course_modules.'+index+'.attachment'] }}
                      </small>
                      <b v-if="course_module.pre_attachment">
                        (<a target = '_blank' :href="trainingElearningServiceBaseUrl + 'storage/uploads/' + course_module.pre_attachment" download>{{$t('globalTrans.view_download')}}</a> )
                      </b>
                    </b-td>
                    <b-td>
                      <button @click="removeAttachment(index, course_module)" class="btn btn-sm btn-danger float-right mt-4" type="button">
                        <i class="fas fa-window-close m-0"></i>
                      </button>
                    </b-td>
                  </b-tr>
                  <b-tr>
                    <b-td colspan="6">
                      <button
                        @click="addMore"
                        class="btn btn-sm btn-primary float-right"
                        type="button"
                      ><i class="fas fa-plus"></i> {{$t('globalTrans.add_more')}}</button>
                    </b-td>
                  </b-tr>
                </b-table-simple>
              </template>
              <div class="row">
                <div class="col-sm-3"></div>
                <div class="col text-right">
                  <router-link to="course-module" class="mr-1 btn btn-sm btn-danger">{{ $t('globalTrans.cancel') }}</router-link>
                  <b-button type="submit" variant="success" class="mr-2 btn-sm">{{ saveBtnName }}</b-button>
                </div>
              </div>
            </body-card>
          </b-form>
        </ValidationObserver>
      </b-overlay>
      </template>
    </card>
  </div>
</template>
<script>

import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { courseModuleStore, courseModuleUpdate, trainingTitleModule } from '../../api/routes'
import flatpickr from 'flatpickr'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.$route.query.training_title_id) {
      const trTitle = this.$store.state.TrainingElearning.commonObj.trainingTitleList.find(item => item.value === parseInt(this.$route.query.training_title_id))
      if (typeof trTitle !== 'undefined') {
        this.formData.org_id = trTitle.org_id
        this.formData.training_type_id = trTitle.training_type_id
        this.formData.training_title_id = this.$route.query.training_title_id
      }
    }
  },
  mounted () {
    flatpickr('.fromDate', {})
  },
  data () {
    return {
      loading: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      formData: {
        org_id: 0,
        training_type_id: 0,
        training_title_id: 0,
        course_modules_remove: [],
        course_modules: [
          {
            id: '',
            course_module: null,
            course_module_bn: null,
            course_module_details: null,
            course_module_details_bn: null,
            status: 1,
            attachment: null
          }
        ]
      },
      officeTypeList: [],
      officeList: [],
      trainingTypeList: [],
      attachments_errors: [],
      circularList: [],
      circularLoading: false,
      circularErrorMsg: false,
      trainingElearningServiceBaseUrl: trainingElearningServiceBaseUrl,
      trainingCalendar: [],
      trainingTitleList: []
    }
  },
  computed: {
    venus: function () {
      return this.$store.state.TrainingElearning.commonObj.venus.filter(item => item.status === 1)
    },
    trainingQuarterSetupList: function () {
      return this.$store.state.TrainingElearning.commonObj.trainingQuarterSetupList.filter(item => item.status === 1)
    },
    orgList: function () {
      return this.$store.state.CommonService.commonObj.componentOrgList.filter(item => item.status === 1)
    },
    fiscalYearList: function () {
      return this.$store.state.commonObj.fiscalYearList.filter(item => item.status === 1)
    }
  },
  watch: {
    'formData.org_id': function (newValue) {
      this.trainingTypeList = this.getTypeList(newValue)
    },
    'formData.training_type_id': function (newValue) {
      this.trainingTitleList = this.getTrainingTitleList(newValue)
    },
    'formData.training_title_id': function (newValue) {
      this.formData.course_modules = this.getFormData()
    }
  },
  methods: {
    addMore () {
      this.formData.course_modules.push({
        id: '',
        course_module: null,
        course_module_bn: null,
        course_module_details: null,
        course_module_details_bn: null,
        attachment: null
      })
    },
    removeAttachment (index, data) {
      this.formData.course_modules.splice(index, 1)
      this.formData.course_modules_remove.push(data)
    },
    async getFormData () {
      this.loading = true
      const searchData = {
        training_type_id: this.formData.training_type_id,
        training_category_id: this.formData.training_category_id,
        training_title_id: this.formData.training_title_id
      }
      const result = await RestApi.getData(trainingElearningServiceBaseUrl, trainingTitleModule, searchData)
      if (!result.success) {
        this.formData.course_modules = [
          {
            id: '',
            course_module: null,
            course_module_bn: null,
            course_module_details: null,
            course_module_details_bn: null,
            attachment: null
          }
        ]
        this.formData.course_modules_remove = []
      } else {
        const courseList = result.data
        const atData = courseList.map(item => {
          const fileData = {}
          fileData.pre_attachment = item.attachment
          return Object.assign({}, item, fileData)
        })
        this.formData.course_modules = atData
        this.formData.course_modules_remove = []
      }
       this.loading = false
    },
    getOfficeTypeList (orgId) {
      const OfficeTypeList = this.$store.state.commonObj.officeTypeList.filter(item => item.status === 0)
      if (orgId) {
        return OfficeTypeList.filter(item => item.org_id === orgId)
      }
      return OfficeTypeList
    },
    getOfficeList (officeTypeId) {
      const officeList = this.$store.state.commonObj.officeList.filter(item => item.status === 0)
      if (officeTypeId) {
        return officeList.filter(item => item.office_type_id === officeTypeId)
      }
      return officeList
    },
    getTypeList (typeId) {
       const trainingTypeList = this.$store.state.TrainingElearning.commonObj.trainingTypeList.filter(item => item.status === 1)
       if (typeId) {
         return trainingTypeList.filter(item => item.org_id === typeId)
       }
       return trainingTypeList
    },
    getTrainingTitleList (trainingTypeId) {
      const trainingTitleList = this.$store.state.TrainingElearning.commonObj.trainingTitleList.filter(item => item.status === 1)
      if (trainingTypeId) {
        return trainingTitleList.filter(item => item.training_type_id === trainingTypeId)
      }
      return trainingTitleList
    },
    onChangeFile (e) {
      this.formData.upload_guidelines = e.target.files[0]
    },
    async createData () {
      this.loading = true
      let result = null
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      }
      var formData = new FormData(document.getElementById('form'))
      Object.keys(this.formData).map(key => {
        if (key !== 'course_modules') {
          formData.append(key, this.formData[key])
        }
      })
      formData.append('course_modules_remove', JSON.stringify(this.formData.course_modules_remove))
      if (this.formData.id) {
        // formData.append('_method', 'POST')
        result = await RestApi.postData(trainingElearningServiceBaseUrl, `${courseModuleUpdate}/${this.formData.id}`, formData, config)
      } else {
        result = await RestApi.postData(trainingElearningServiceBaseUrl, courseModuleStore, formData, config)
      }
      this.loading = false
      if (result.success) {
        this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
        this.$toast.success({
            title: 'Success',
            message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
        })
        this.$router.push('/training-e-learning-service/tpm/course-module')
      } else {
        this.attachments_errors = result.errors
        this.$refs.form.setErrors(result.errors)
      }
  }
  }
}
</script>
